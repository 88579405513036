import React from "react";
import {
  StyledButtonBlock,
} from "./style";
import useNavigate from "../../hooks/useNavigate";
import { Button } from "@3beehivetech/components";
import { StyledTextButtonBig } from "../globalstyle";

export default ({
  label,
  destination,
}) => {
  return (
    <StyledButtonBlock >
        
      <Button
        onClick={useNavigate(destination)}
        noLine
      >
        <StyledTextButtonBig dangerouslySetInnerHTML={{ __html: label }}/>
      </Button>
        
    </StyledButtonBlock>
  );
};
